/* Light Mode */
:root, [data-theme='light']{
  --background-brand: var(--color-neutral-1000);
  --background-bronze: var(--color-black-50);
  --background-disabled: var(--color-neutral-50);
  --background-error-emphazis: var(--color-red-500);
  --background-error-red: var(--color-red-50);
  --background-focustext: var(--color-blue-50);
  --background-form: var(--color-lightgray-500);
  --background-gold: var(--color-black-50);
  --background-infos: var(--color-blue-50);
  --background-infos-emphazis: var(--color-blue-500);
  --background-inverse: var(--color-neutral-950);
  --background-l0: var(--color-neutral-0);
  --background-l1: var(--color-neutral-0);
  --background-l2: var(--color-neutral-0);
  --background-l3: var(--color-neutral-0);
  --background-l4: var(--color-neutral-0);
  --background-l5: var(--color-neutral-0);
  --background-l6: var(--color-neutral-0);
  --background-neutral: var(--color-neutral-50);
  --background-overlay: var(--color-black-50);
  --background-silver: var(--color-black-50);
  --background-skeleton: var(--color-neutral-150);
  --background-success: var(--color-green-50);
  --background-success-emphazis: var(--color-green-500);
  --background-tertiary: var(--color-neutral-100);
  --background-warning: var(--color-orange-50);
  --background-warning-emphazis: var(--color-orange-400);
  --border-brand: var(--color-neutral-900);
  --border-bronze: var(--color-black-50);
  --border-corail: var(--color-black-50);
  --border-disabled: var(--color-neutral-200);
  --border-error: var(--color-red-700);
  --border-gold: var(--color-black-50);
  --border-info: var(--color-blue-700);
  --border-primary: var(--color-neutral-150);
  --border-rose: var(--color-black-50);
  --border-selected: var(--color-neutral-900);
  --border-silver: var(--color-black-50);
  --border-subtle: var(--color-neutral-100);
  --border-success: var(--color-green-700);
  --border-violet: var(--color-black-50);
  --border-warning: var(--color-orange-700);
  --border-yellow: var(--color-black-50);
  --coral-light: var(--color-black-50);
  --coral-medium: var(--color-black-50);
  --coral-strong: var(--color-black-50);
  --green-light: var(--color-black-50);
  --green-medium: var(--color-black-50);
  --green-strong: var(--color-black-50);
  --lila-light: var(--color-lila-100);
  --lila-medium: var(--color-lila-300);
  --lila-strong: var(--color-lila-500);
  --orange-light: var(--color-black-50);
  --orange-medium: var(--color-black-50);
  --orange-strong: var(--color-black-50);
  --rose-light: var(--color-black-50);
  --rose-medium: var(--color-black-50);
  --rose-strong: var(--color-black-50);
  --surface-brand: var(--color-neutral-1000);
  --surface-brand-hover: var(--color-neutral-800);
  --surface-brand-pressed: var(--color-neutral-700);
  --surface-neutral: var(--color-neutral-50);
  --surface-neutral-hover: var(--color-neutral-100);
  --surface-neutral-pressed: var(--color-neutral-150);
  --surface-primary: var(--color-neutral-0);
  --surface-primary-hover: var(--color-neutral-50);
  --surface-primary-pressed: var(--color-neutral-100);
  --surface-promotion: var(--color-tulipe-500);
  --surface-secondary: var(--color-neutral-50);
  --surface-secondary-hover: var(--color-neutral-100);
  --surface-secondary-pressed: var(--color-neutral-150);
  --text-brand: var(--color-neutral-0);
  --text-bronze: var(--color-black-50);
  --text-corail: var(--color-black-50);
  --text-disabled: var(--color-neutral-700);
  --text-error: var(--color-red-700);
  --text-gold: var(--color-black-50);
  --text-infos: var(--color-blue-700);
  --text-primary: var(--color-neutral-1000);
  --text-primary-fixed: var(--color-neutral-900);
  --text-primary-inverse: var(--color-neutral-50);
  --text-primary-inverse-fixed: var(--color-neutral-50);
  --text-promotion: var(--color-tulipe-700);
  --text-rose: var(--color-black-50);
  --text-secondary: var(--color-neutral-800);
  --text-secondary-inverse: var(--color-neutral-300);
  --text-secondary-inverse-fixed: var(--color-neutral-300);
  --text-sephora-collection: var(--color-fushia-700);
  --text-silver: var(--color-black-50);
  --text-success: var(--color-green-700);
  --text-tertiary: var(--color-neutral-650);
  --text-tertiary-inverse: var(--color-neutral-200);
  --text-tertiary-inverse-fixed: var(--color-neutral-200);
  --text-violet: var(--color-black-50);
  --text-warning: var(--color-orange-700);
  --text-yellow: var(--color-black-50);
  --turquoise-light: var(--color-turquoise-200);
  --turquoise-medium: var(--color-turquoise-400);
  --turquoise-strong: var(--color-turquoise-600);
  --yellow-light: var(--color-black-50);
  --yellow-medium: var(--color-black-50);
  --yellow-strong: var(--color-black-50);
}

/* Dark Mode */
[data-theme='dark']{
  --background-brand: var(--color-neutral-1000);
  --background-bronze: var(--color-black-50);
  --background-disabled: var(--color-neutral-850);
  --background-error-emphazis: var(--color-red-400);
  --background-error-red: var(--color-red-800);
  --background-focustext: var(--color-blue-100);
  --background-form: var(--color-lightgray-900);
  --background-gold: var(--color-black-50);
  --background-infos: var(--color-blue-800);
  --background-infos-emphazis: var(--color-blue-400);
  --background-inverse: var(--color-neutral-0);
  --background-l0: var(--color-neutral-1000);
  --background-l1: var(--color-neutral-950);
  --background-l2: var(--color-neutral-900);
  --background-l3: var(--color-neutral-850);
  --background-l4: var(--color-neutral-800);
  --background-l5: var(--color-neutral-750);
  --background-l6: var(--color-neutral-700);
  --background-neutral: var(--color-neutral-800);
  --background-overlay: var(--color-black-50);
  --background-silver: var(--color-black-50);
  --background-skeleton: var(--color-neutral-850);
  --background-success: var(--color-green-800);
  --background-success-emphazis: var(--color-green-400);
  --background-tertiary: var(--color-neutral-700);
  --background-warning: var(--color-orange-800);
  --background-warning-emphazis: var(--color-orange-400);
  --border-brand: var(--color-neutral-50);
  --border-bronze: var(--color-black-50);
  --border-corail: var(--color-black-50);
  --border-disabled: var(--color-neutral-800);
  --border-error: var(--color-red-500);
  --border-gold: var(--color-black-50);
  --border-info: var(--color-blue-500);
  --border-primary: var(--color-neutral-500);
  --border-rose: var(--color-black-50);
  --border-selected: var(--color-neutral-50);
  --border-silver: var(--color-black-50);
  --border-subtle: var(--color-neutral-500);
  --border-success: var(--color-green-500);
  --border-violet: var(--color-black-50);
  --border-warning: var(--color-orange-500);
  --border-yellow: var(--color-black-50);
  --coral-light: var(--color-black-50);
  --coral-medium: var(--color-black-50);
  --coral-strong: var(--color-black-50);
  --green-light: var(--color-black-50);
  --green-medium: var(--color-black-50);
  --green-strong: var(--color-black-50);
  --lila-light: var(--color-lila-200);
  --lila-medium: var(--color-lila-300);
  --lila-strong: var(--color-lila-500);
  --orange-light: var(--color-black-50);
  --orange-medium: var(--color-black-50);
  --orange-strong: var(--color-black-50);
  --rose-light: var(--color-black-50);
  --rose-medium: var(--color-black-50);
  --rose-strong: var(--color-black-50);
  --surface-brand: var(--color-neutral-0);
  --surface-brand-hover: var(--color-neutral-100);
  --surface-brand-pressed: var(--color-neutral-200);
  --surface-neutral: var(--color-neutral-800);
  --surface-neutral-hover: var(--color-neutral-750);
  --surface-neutral-pressed: var(--color-neutral-700);
  --surface-primary: var(--color-neutral-900);
  --surface-primary-hover: var(--color-neutral-800);
  --surface-primary-pressed: var(--color-neutral-700);
  --surface-promotion: var(--color-tulipe-600);
  --surface-secondary: var(--color-neutral-850);
  --surface-secondary-hover: var(--color-neutral-750);
  --surface-secondary-pressed: var(--color-neutral-650);
  --text-brand: var(--color-neutral-950);
  --text-bronze: var(--color-black-50);
  --text-corail: var(--color-black-50);
  --text-disabled: var(--color-neutral-600);
  --text-error: var(--color-red-100);
  --text-gold: var(--color-black-50);
  --text-infos: var(--color-blue-100);
  --text-primary: var(--color-neutral-0);
  --text-primary-fixed: var(--color-neutral-900);
  --text-primary-inverse: var(--color-neutral-900);
  --text-primary-inverse-fixed: var(--color-neutral-50);
  --text-promotion: var(--color-tulipe-100);
  --text-rose: var(--color-black-50);
  --text-secondary: var(--color-neutral-100);
  --text-secondary-inverse: var(--color-neutral-800);
  --text-secondary-inverse-fixed: var(--color-neutral-300);
  --text-sephora-collection: var(--color-fushia-300);
  --text-silver: var(--color-black-50);
  --text-success: var(--color-green-100);
  --text-tertiary: var(--color-neutral-200);
  --text-tertiary-inverse: var(--color-neutral-550);
  --text-tertiary-inverse-fixed: var(--color-neutral-200);
  --text-violet: var(--color-black-50);
  --text-warning: var(--color-orange-100);
  --text-yellow: var(--color-black-50);
  --turquoise-light: var(--color-turquoise-200);
  --turquoise-medium: var(--color-turquoise-400);
  --turquoise-strong: var(--color-turquoise-600);
  --yellow-light: var(--color-black-50);
  --yellow-medium: var(--color-black-50);
  --yellow-strong: var(--color-black-50);
}

