:root {
  --color-black-50: hsla(0, 0%, 0%, 0.5);
  --color-blue-100: hsl(209, 73%, 86%, 1);
  --color-blue-200: hsl(209, 68%, 76%, 1);
  --color-blue-300: hsl(208, 63%, 65%, 1);
  --color-blue-400: hsl(208, 57%, 55%, 1);
  --color-blue-50: hsl(210, 75%, 95%, 1);
  --color-blue-500: hsl(207, 64%, 44%, 1);
  --color-blue-600: hsl(204, 100%, 31%, 1);
  --color-blue-700: hsl(206, 100%, 26%, 1);
  --color-blue-800: hsl(209, 100%, 20%, 1);
  --color-blue-900: hsl(212, 100%, 14%, 1);
  --color-fushia-100: hsl(337, 100%, 88%, 1);
  --color-fushia-200: hsl(336, 100%, 80%, 1);
  --color-fushia-300: hsl(334, 87%, 70%, 1);
  --color-fushia-400: hsl(332, 74%, 59%, 1);
  --color-fushia-50: hsl(337, 100%, 96%, 1);
  --color-fushia-500: hsl(329, 73%, 49%, 1);
  --color-fushia-600: hsl(327, 100%, 35%, 1);
  --color-fushia-700: hsl(329, 100%, 29%, 1);
  --color-fushia-800: hsl(331, 100%, 22%, 1);
  --color-fushia-900: hsl(334, 100%, 15%, 1);
  --color-green-100: hsl(114, 41%, 81%, 1);
  --color-green-200: hsl(114, 39%, 69%, 1);
  --color-green-300: hsl(115, 36%, 57%, 1);
  --color-green-400: hsl(115, 41%, 45%, 1);
  --color-green-50: hsl(111, 44%, 94%, 1);
  --color-green-500: hsl(115, 64%, 33%, 1);
  --color-green-600: hsl(120, 100%, 23%, 1);
  --color-green-700: hsl(120, 100%, 18%, 1);
  --color-green-800: hsl(120, 100%, 13%, 1);
  --color-green-900: hsl(120, 100%, 8%, 1);
  --color-lightgray-100: hsl(0, 0%, 100%, 1);
  --color-lightgray-200: hsl(0, 0%, 99%, 1);
  --color-lightgray-300: hsl(0, 0%, 99%, 1);
  --color-lightgray-400: hsl(0, 0%, 99%, 1);
  --color-lightgray-50: hsl(0, 0%, 100%, 1);
  --color-lightgray-500: hsl(0, 0%, 98%, 1);
  --color-lightgray-600: hsl(0, 0%, 79%, 1);
  --color-lightgray-700: hsl(0, 0%, 59%, 1);
  --color-lightgray-800: hsl(0, 0%, 39%, 1);
  --color-lightgray-900: hsl(0, 0%, 18%, 1);
  --color-lila-100: hsl(282, 81%, 96%, 1);
  --color-lila-200: hsl(281, 76%, 92%, 1);
  --color-lila-300: hsl(281, 77%, 88%, 1);
  --color-lila-400: hsl(282, 77%, 85%, 1);
  --color-lila-50: hsl(291, 64%, 98%, 1);
  --color-lila-500: hsl(283, 76%, 80%, 1);
  --color-lila-600: hsl(283, 78%, 65%, 1);
  --color-lila-700: hsl(283, 71%, 51%, 1);
  --color-lila-800: hsl(283, 70%, 34%, 1);
  --color-lila-900: hsl(284, 74%, 16%, 1);
  --color-neutral-0: hsl(0, 0%, 100%, 1);
  --color-neutral-100: hsl(0, 0%, 88%, 1);
  --color-neutral-1000: hsl(0, 0%, 0%, 1);
  --color-neutral-150: hsl(0, 0%, 84%, 1);
  --color-neutral-200: hsl(0, 0%, 80%, 1);
  --color-neutral-250: hsl(0, 0%, 75%, 1);
  --color-neutral-300: hsl(0, 0%, 70%, 1);
  --color-neutral-350: hsl(0, 0%, 65%, 1);
  --color-neutral-400: hsl(0, 0%, 60%, 1);
  --color-neutral-450: hsl(0, 0%, 55%, 1);
  --color-neutral-50: hsl(0, 0%, 95%, 1);
  --color-neutral-500: hsl(0, 0%, 50%, 1);
  --color-neutral-550: hsl(0, 0%, 45%, 1);
  --color-neutral-600: hsl(0, 0%, 40%, 1);
  --color-neutral-650: hsl(0, 0%, 35%, 1);
  --color-neutral-700: hsl(0, 0%, 30%, 1);
  --color-neutral-750: hsl(0, 0%, 25%, 1);
  --color-neutral-800: hsl(0, 0%, 20%, 1);
  --color-neutral-850: hsl(0, 0%, 15%, 1);
  --color-neutral-900: hsl(0, 0%, 10%, 1);
  --color-neutral-950: hsl(0, 0%, 5%, 1);
  --color-neutral-test-100: hsl(0, 0%, 84%, 1);
  --color-neutral-test-200: hsl(0, 0%, 75%, 1);
  --color-neutral-test-300: hsl(0, 0%, 64%, 1);
  --color-neutral-test-400: hsl(0, 0%, 55%, 1);
  --color-neutral-test-50: hsl(0, 0%, 95%, 1);
  --color-neutral-test-500: hsl(0, 0%, 45%, 1);
  --color-neutral-test-600: hsl(0, 0%, 36%, 1);
  --color-neutral-test-700: hsl(0, 0%, 28%, 1);
  --color-neutral-test-800: hsl(0, 0%, 20%, 1);
  --color-neutral-test-900: hsl(0, 0%, 12%, 1);
  --color-neutral-test-999: hsl(0, 0%, 0%, 1);
  --color-orange-100: hsl(21, 100%, 84%, 1);
  --color-orange-200: hsl(21, 91%, 73%, 1);
  --color-orange-300: hsl(22, 82%, 61%, 1);
  --color-orange-400: hsl(27, 100%, 43%, 1);
  --color-orange-50: hsl(22, 100%, 95%, 1);
  --color-orange-500: hsl(21, 100%, 39%, 1);
  --color-orange-600: hsl(13, 100%, 34%, 1);
  --color-orange-700: hsl(1, 100%, 28%, 1);
  --color-orange-800: hsl(0, 100%, 21%, 1);
  --color-orange-900: hsl(0, 100%, 15%, 1);
  --color-red-100: hsl(10, 100%, 86%, 1);
  --color-red-200: hsl(8, 100%, 77%, 1);
  --color-red-300: hsl(6, 100%, 68%, 1);
  --color-red-400: hsl(3, 91%, 58%, 1);
  --color-red-50: hsl(0, 78%, 93%, 1);
  --color-red-500: hsl(357, 100%, 46%, 1);
  --color-red-600: hsl(0, 100%, 38%, 1);
  --color-red-700: hsl(0, 100%, 30%, 1);
  --color-red-800: hsl(0, 100%, 24%, 1);
  --color-red-900: hsl(0, 100%, 17%, 1);
  --color-tulipe-100: hsl(3, 100%, 88%, 1);
  --color-tulipe-200: hsl(1, 100%, 80%, 1);
  --color-tulipe-300: hsl(359, 97%, 72%, 1);
  --color-tulipe-400: hsl(356, 82%, 61%, 1);
  --color-tulipe-50: hsl(345, 100%, 94%, 1);
  --color-tulipe-500: hsl(346, 100%, 42%, 1);
  --color-tulipe-600: hsl(351, 100%, 37%, 1);
  --color-tulipe-700: hsl(0, 100%, 30%, 1);
  --color-tulipe-800: hsl(0, 100%, 23%, 1);
  --color-tulipe-900: hsl(0, 100%, 16%, 1);
  --color-turquoise-100: hsl(196, 85%, 97%, 1);
  --color-turquoise-200: hsl(194, 76%, 94%, 1);
  --color-turquoise-300: hsl(195, 78%, 91%, 1);
  --color-turquoise-400: hsl(194, 77%, 88%, 1);
  --color-turquoise-50: hsl(197, 78%, 98%, 1);
  --color-turquoise-500: hsl(197, 78%, 85%, 1);
  --color-turquoise-600: hsl(196, 77%, 72%, 1);
  --color-turquoise-700: hsl(199, 76%, 63%, 1);
  --color-turquoise-800: hsl(199, 46%, 41%, 1);
  --color-turquoise-900: hsl(198, 50%, 21%, 1);
}
