/* Light Mode */
:root, [data-theme='light']{
   --shadow-strong-color: hsl(0, 0%, 0%, 0.4);
   --shadow-light-color: hsl(0, 0%, 0%, 0.1);
   --shadow-sticky-color: hsl(0, 0%, 0%, 0.1);
   --shadow-store-pin-color: hsl(0, 0%, 0%, 0.22);
}

/* Dark Mode */
[data-theme='dark']{
   --shadow-strong-color: hsl(0, 0%, 100%, 0.4);
   --shadow-light-color: hsl(0, 0%, 100%, 0.1);
   --shadow-sticky-color: hsl(0, 0%, 100%, 0.1);
   --shadow-store-pin-color: hsl(0, 0%, 100%, 0.22);
}

